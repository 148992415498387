// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledToggleLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	width: 36px;
	height: 16px;
	background-color: var(--dz-ref-color-gray600);
	border-radius: 15px;
	transition: background-color 0.1s ease-in-out;
	cursor: pointer;
	${({ isChecked }) =>
		isChecked &&
		css`
			background-color: ${({ theme: { colors, isDarkMode } }) =>
				isDarkMode ? colors.primaryLight : colors.primary};
		`}
`;

// #####################################################
